export default () => {
	return {
		tree: [],
		roleId: 57,
		changeSize: 30,
		projectId: null, // 项目id（新增
		isIndeterminate: false, //是否出现非全选样式（新增
		checkAll: false, //是否全选（新增
		checkedDevice: [], //多选框选中数据的id
		showRelevance: false,
		dataSize: 0,
		dataPoint: 0,
		searchType: {
			id: 1,
			name: '企业'
		},
		placeholder: '企业',
		enterprise: [],
		project: [],
		stationLine: [],
		station: [],
		deviceType: [],
		batchParse: [],
		selectRelevanceData: {},
		item: {
			rows: [],
			page: 1,
			size: 30,
			allPage: 0,
			total: 0,
			time: false,
			type: "reportSta",
			dataType: "list",
			text: "暂时没有数据哦--",
		},
		form: {
			organization: null,
			type: null,
			station: null,
			timeS: null,
			timeE: null,
		},
		state: null,//选择的筛选条件
		stateOptions: [
			{
				value: 2,
				name: '全部'
			},
			{
				value: 0,
				name: '合格'
			},
			{
				value: 1,
				name: '不合格'
			}
		],//条件选择数据列表
		that: null,
	}
}
