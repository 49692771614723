<template>
	<div class="reportExpPage pageContainer">
		<div class="pageMenu">
			<ul class="nav no-select-text">
				<li class="selected">爆次报告</li>
				<li @click="switchNavMenu('reportSta')">测点报告</li>
				<li @click="switchNavMenu('summary')" v-show="judgment('authorityList', 157, this)">总结报告</li>
			</ul>

			<div class="right-box">
				<el-select v-model="state" placeholder="请选择结论">
					<el-option v-for="item in stateOptions" :key="item.value" :label="item.name" :value="item.value"></el-option>
				</el-select>
				<div class="screen time">
					<span>时段：</span>
					<el-date-picker v-model="form.timeS" type="datetime" :clearable="false" value-format="yyyy-MM-dd HH:mm:ss" placeholder="选择日期时间"></el-date-picker>
					<span>至</span>
					<el-date-picker v-model="form.timeE" type="datetime" :clearable="false" value-format="yyyy-MM-dd HH:mm:ss" placeholder="选择日期时间"></el-date-picker>
				</div>
				<Button type="search" :button-callback="buttonCallback" text="查询" />
			</div>
		</div>

		<div class="tableHeader no-select-text">
			<span style="width: 5%;"><el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="selectAllDevice">全选</el-checkbox></span>
			<span style="width: 3%;">序号</span>
			<span style="width: 18%;text-align: center;">起爆时间</span>
			<span style="width: 25%;">报告名称</span>
			<span style="width: 10%;">最大值</span>
			<span style="width: 7%;letter-spacing: 16px;">结果</span>
			<span style="width: 17%;text-align: center;">创建时间</span>
			<span style="width: 12%;text-align: center;text-indent: 0">编辑</span>
		</div>

		<div class="tableContainer">
			<div class="tableContent">
				<vue-scroll v-if="item.total">
					<el-checkbox-group v-model="checkedDevice" @change="selectDevice">
						<ul class="tableList" v-for="(p, i) in dataList">
							<li class="no-select-text" style="width: 5%;"><el-checkbox :label="p.id"></el-checkbox></li>
							<li style="width: 3%;">{{ getListNumber(i, item) }}</li>
							<li style="width: 18%;text-align: center;" :title="p.beginTime">
								<span class="blue">{{ p.beginTime | dataDetect }}</span>
							</li>
							<li style="width: 25%;" :title="p.name">{{ p.name | dataDetect }}</li>
							<li style="width: 10%;" :title="p.max">{{ p.max | dataDetect }}</li>
							<li style="width: 7%;" :title="p.overNum == 0 ? '未超限' : '超限'">
								<span v-show="p.overNum == 0">未超限</span>
								<span class="reds" style="letter-spacing: 16px;" v-show="p.overNum != 0">超限</span>
							</li>
							<li style="width: 17%;text-align: center;" :title="p.createDate">{{ p.createDate | dataDetect }}</li>
							<li style="width: 12%;text-align: center;" class="compile">
								<i @click="preview(p, $event)" title="报表预览" class="icon iconfont icon-yulan outline blue"></i>
								<i @click="removeList(p, i)" title="移出列表" class="iconfont icon-yichu delete red"></i>
							</li>
						</ul>
					</el-checkbox-group>
				</vue-scroll>
				<noData v-if="!item.total" />
			</div>

			<div class="tableFooter">
				<!--        <Button class="downloadTable" type="downloadTable" :button-callback="buttonCallback" text="下载表单"/>-->
				<Button class="downloadData" type="downloadData" :button-callback="buttonCallback" text="下载报告" />
				<!--        <Button class="createWord" type="createWord" :button-callback="buttonCallback" text="生成报告"/>-->
				<Button class="deleteData" :disabled="!judgment('authorityList', 144, that)" type="deleteData" :button-callback="buttonCallback" text="删除数据" />

				<div class="sizeBox">
					<span>单页显示条数</span>
					<input type="number" v-model="changeSize" autocomplete="off" />
				</div>

				<Pagination :item="item" :current-page-change="currentPageChange"></Pagination>
			</div>
		</div>
	</div>
</template>

<script>
import reportExp from './reportExp.js';

export default reportExp;
</script>

<style scoped lang="scss">
@import 'reportExp';
</style>
