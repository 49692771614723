<template>
  <div class="myCascader">
    <div @click.stop="showCascaderDropdown" class="cascaderInput">
      <!--        <input type="text" readonly :value="selectedNode.name" :placeholder="placeholder">-->
      <!--        <input type="text" name="t" v-model="searchInput" :readonly="readonly" :placeholder="placeholder" autocomplete="off">-->
      <textarea v-model="searchInput" :placeholder="placeholder"></textarea>
      <i class="el-icon-arrow-down"></i>
    </div>

    <div class="cascaderDropdown">
      <div class="cascaderItem">
        <el-scrollbar :native="false" :noresize="false" tag="div">
          <ul>
            <li :class="selectID.one === o.id ? 'select' : ''"
                @click.stop="selectCascader(o)"
                @mouseover="hoverCascader(o, i, true, 1)"
                @mouseleave="hoverCascader(o, i, false, 1)"
                v-for="(o, i) in one" v-if="o.type !== 4">
              <span :title="o.name ? o.name : o.deviceNum">{{ o.name ? o.name : o.deviceNum }}</span>
              <i v-if="o.children && o.children.length"
                 :class=" o.type === 3 && o.children && o.children.length === 1 ? 'icon iconfont icon-device' : 'el-icon-arrow-right'"></i>
            </li>
          </ul>
        </el-scrollbar>
      </div>
      <div class="cascaderItem" v-if="type.one !== 3 && two.length">
        <el-scrollbar :native="false" :noresize="false" tag="div">
          <ul>
            <li :class="selectID.two === o.id ? 'select' : ''"
                @click.stop="selectCascader(o)"
                @mouseover="hoverCascader(o, i, true, 2)"
                @mouseleave="hoverCascader(o, i, false, 2)"
                v-for="(o, i) in two">
              <span :title="o.name ? o.name : o.deviceNum">{{ o.name ? o.name : o.deviceNum }}</span>
              <i v-if="o.children && o.children.length"
                 :class=" o.type === 3 && o.children && o.children.length === 1 ? 'icon iconfont icon-device' : 'el-icon-arrow-right'"></i>
            </li>
          </ul>
        </el-scrollbar>
      </div>
      <div class="cascaderItem" v-if="type.two !== 3 && three.length">
        <el-scrollbar :native="false" :noresize="false" tag="div">
          <ul>
            <li :class="selectID.three === o.id ? 'select' : ''"
                @click.stop="selectCascader(o)"
                @mouseover="hoverCascader(o, i, true, 3)"
                @mouseleave="hoverCascader(o, i, false, 3)"
                v-for="(o, i) in three">
              <span :title="o.name ? o.name : o.deviceNum">{{ o.name ? o.name : o.deviceNum }}</span>
              <i v-if="o.children && o.children.length"
                 :class=" o.type === 3 && o.children && o.children.length === 1 ? 'icon iconfont icon-device' : 'el-icon-arrow-right'"></i>
            </li>
          </ul>
        </el-scrollbar>
      </div>
      <div class="cascaderItem" v-if="type.three !== 3 && four.length">
        <el-scrollbar :native="false" :noresize="false" tag="div">
          <ul>
            <li :class="selectID.four === o.id ? 'select' : ''"
                @click.stop="selectCascader(o)"
                @mouseover="hoverCascader(o, i, true, 4)"
                @mouseleave="hoverCascader(o, i, false, 4)"
                v-for="(o, i) in four">
              <span :title="o.name ? o.name : o.deviceNum">{{ o.name ? o.name : o.deviceNum }}</span>
              <i v-if="o.children && o.children.length"
                 :class=" o.type === 3 && o.children && o.children.length === 1 ? 'icon iconfont icon-device' : 'el-icon-arrow-right'"></i>
            </li>
          </ul>
        </el-scrollbar>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "myCascader",
  props: {
    options: Array,
    placeholder: String,
    change: {
      type: Function,
      required: false
    },
  },
  data() {
    return {
      two: [],
      three: [],
      four: [],
      selectedNode: [],
      searchInput: '',
      readonly: true,
      selectID: {
        one: 0,
        two: 0,
        three: 0,
        four: 0
      },
      type: {
        one: 0,
        two: 0,
        three: 0,
        four: 0
      },
    }
  },
  computed: {
    one() {
			// // console.log(this.options)
      return this.localSearchData(this.searchInput, this.options, 'name');
    }
  },
  methods: {
    //显示下拉列表
    showCascaderDropdown(e) {
        sessionStorage.removeItem("pointId")
      let self = $(e.currentTarget);
      let parent = self.parent();
      if (!parent.hasClass('show')) {
        parent.addClass('show');
        this.searchInput = '';
        this.readonly = false;
      } else {
        parent.removeClass('show');
        this.readonly = true;
      }
    },
    //hover时显示下一级的选项
    hoverCascader(item, index, boolean, level) {
      this.judgeDevice(item)
      if (boolean && item.type !== 4) {
        if (level === 1) {
          this.selectID.one = item.id;
          this.selectID.two = 0;
          this.selectID.three = 0;
          this.selectID.four = 0;

          this.type.one = item.type;
          this.type.two = 0;
          this.type.three = 0;
          this.type.four = 0;

          this.two = item.children ? item.children : []
          this.three = [];
          this.four = [];
        } else if (level === 2) {
          this.selectID.two = item.id;
          this.selectID.three = 0;
          this.selectID.four = 0;

          this.type.two = item.type;
          this.type.three = 0;
          this.type.four = 0;

          this.three = item.children ? item.children : [];
          this.four = [];
        } else if (level === 3) {
          this.selectID.three = item.id;
          this.selectID.four = 0;

          this.type.three = item.type;
          this.type.four = 0;

          this.four = item.children ? item.children : []
        }
      }
    },
    //当前节点为测点时，判断当前测点下有没有设备
    judgeDevice(item) {
      if (item.type === 3) {
        item.device = item.children.length;
      }
    },
    //选择某一级的节点信息
    selectCascader(item) {
      // if(item.type === -1){
      //   this.$messages('未分组测点不可用作筛选', 'warning');
      //   return false;
      // }
      this.selectedNode = item;
      this.searchInput = item.name;
      if (item.type === 0) {
        this.selectID.two = 0;
        this.selectID.three = 0;
        this.selectID.four = 0;
      } else if (item.type === 1) {
        this.selectID.three = 0;
        this.selectID.four = 0;
      }
      $('.myCascader.show').removeClass('show');
      if (typeof this.change === "function") {
        this.change(this.selectedNode)
      }
    },
    //获取选中的节点信息
    getCascader() {
      return this.selectedNode;
    },
    //清除选中内容
    clearCascader() {
      this.selectedNode = [];
      this.searchInput = '';
      this.two = [];
      this.three = [];
      this.four = [];
      for (let i in this.selectID) {
        this.selectID[i] = 0
        this.type[i] = 0
      }
    }
  },
  mounted() {
	  // // console.log(this.one)
    $('body').click(() => {
      $('.myCascader.show').removeClass('show');
    });
    $('.myCascader').click(e => e.stopPropagation());
  }
}
</script>

<style scoped lang="scss">
.myCascader {
  display: inline-block;
  position: relative;
  z-index: 10;

  .cascaderInput {
    height: 30px;
    line-height: 28px;
    width: 190px;
    border: 1px solid #E5E5E5;
    box-sizing: border-box;
    display: flex;
    background-color: #fff;

    input {
      flex: 1 1 auto;
      width: 100%;
      float: left;
      height: 100%;
      border: 0;
      padding-left: 10px;
      box-sizing: border-box;
      font-size: 12px;
      color: #555;
      border-radius: 0;
    }

    textarea {
      height: 28px;
      line-height: 28px;
      flex: 1 1 auto;
      width: 100%;
      float: left;
      border: 0;
      margin-left: 10px;
      box-sizing: border-box;
      font-size: 12px;
      color: #555;
      border-radius: 0;
      overflow: hidden;
      white-space: nowrap;
      outline: none;
      resize: none;
    }

    textarea::-webkit-input-placeholder,
    input::-webkit-input-placeholder {
      /* placeholder颜色  */
      color: #c4c4c4;
      /* placeholder字体大小  */
      font-size: 12px;
      /* placeholder位置  */
      text-align: left;
    }

    i {
      color: #bbb;
      height: 28px;
      width: 28px;
      flex: 0 0 28px;
      text-align: center;
      line-height: 28px;
      background-color: #fff;
    }
  }

  .cascaderDropdown {
    position: absolute;
    font-size: 14px;
    background: #fff;
    border-radius: 4px;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    display: flex;
    height: 0;
    overflow: hidden;
    transition: height 0.3s;

    .cascaderItem {
      height: 250px;
      min-width: 180px;
      max-width: 350px;
      display: flex;
      border-right: 1px solid #E5E5E5;

      ul {
        overflow: hidden;
        line-height: 34px;
        min-width: 180px;
        max-width: 300px;
        display: flex;
        flex-flow: column;

        li {
          margin: 0;
          padding: 0 10px;
          display: flex;
          max-width: 350px;
          box-sizing: border-box;
          cursor: pointer;
          font-size: 12px;
          color: #333;

          span {
            flex: 1 1 auto;
            width: 100%;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
          }

          i {
            flex: 0 0 14px;
            width: 14px;
            height: 34px;
            text-align: center;
            line-height: 34px;
            margin-left: 10px;
          }
        }

        li:hover {
          background-color: #f5f7fa;
        }

        li.select {
          color: #1c8cfa;
        }
      }

    }

    .cascaderItem:last-child {
      border-right: 0;
    }
  }
}

.myCascader.show {
  .cascaderDropdown {
    height: 250px;
    border-color: #E4E7ED;
    background: #fff;
  }
}
</style>
