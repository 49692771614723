import data from "./summaryData.js"
import myCascader from "../../../../components/myCascader.vue";

export default {
	name: "summaryPage",
	components: {
		myCascader
	},
	props: {
		switchNavMenu: {
			type: Function,
			required: true
		},
		authorityList:{
			type: Array,
			default: ()=>{
				return []
			}
		}
	},
	data,
	created() {
		const D = new Date();
		let before = new Date(D.getTime() - 24 * 60 * 60 * 1000 * 7).format('yyyy/MM/dd') + ' 00:00:00'
		this.form.timeS = before;
		// let now = new Date(D.getTime()- 24 * 60 * 60 * 1000).format('yyyy/MM/dd') + ' 11:15:59';
		let now = new Date(D.getTime()).format('yyyy/MM/dd') + ' 23:59:59';
		this.form.timeE = now;
		this.that = this;
		this.projectId = this.$route.query.id;
		// this.getDeviceFile();
	},
	computed: {},
	methods: {
		// 请求数据并设置参数
		setTheParameters(value) {
			// console.log('总结')
			this.projectId = value.id;
			this.getDeviceFile();
		},
		//翻页组件触发分页回调
		currentPageChange(type) {
			if (type === 'reportSta') {
				if (this.changeSize !== this.item.size) {
					this.item.size = this.changeSize;
					this.item.page = 1;
				}
				this.getDeviceFile()
			}
		},
		//按钮组件点击事件回调
		buttonCallback(type) {
			if (this.checkedDevice.length === 0 && type !== 'search') {
				this.$messages('请选择数据', 'error');
				return false;
			}
			if (type === 'downloadData') {
				this.downloadFile();
			} else if (type === 'deleteData') {
				this.deleteFileData();
			} else if (type === 'search') {
				this.item.page = 1;
				this.item.size = this.changeSize;
				this.item.time = true;
				this.getDeviceFile();
			} else if (type === 'createWord') {
				this.$parent.buttonCallback(this.checkedDevice, 'sta', this.dataSize, this.dataPoint, this.item.rows);
			}
		},
		//获取设备文件列表
		getDeviceFile() {
			const {
				page,
				size
			} = this.item;
			const data = {};
			data.page = page;
			data.size = size;
			data.id = this.projectId;

			// if (this.item.time) {
			// 	data.begin = this.form.timeS;
			// 	data.end = this.form.timeE;

			// 	if (data.begin > data.end) {
			// 		this.$messages('开始时间大于结束时间，请重新选择', 'warning', 3000)
			// 		return false
			// 	}
			// }
			this.isIndeterminate = false;
			this.checkAll = false;
			this.checkedDevice = [];
			this.axios.post(`web2/report/odnr/gsplist`, data).then(res => {
				this.item.rows = [];
				this.item.total = 0;
				this.item.allPage = 1;
				if (res.status) {
					this.item.rows = res.data.list;
					this.item.total = res.data.total;
					this.item.allPage = Math.ceil(this.item.total / size);
					let len = res.data.list.length;
					if(page != 1 && len == 0 && res.data.total != 0){
						this.item.page = page - 1;
						this.getDeviceFile();
					}
				}
			})
		},
		//选中设备列表
		selectDevice(p) {
			// // console.log(p);
			this.isIndeterminate = p.length > 0 && this.item.rows.length > this.checkedDevice.length;
			this.checkAll = this.item.rows.length == this.checkedDevice.length;
		},
		//全选当前页
		selectAllDevice(val) {
			if (val) {
				this.checkedDevice = [];
				this.item.rows.forEach((item) => {
					this.checkedDevice.push(item.id)
				})
			} else {
				this.checkedDevice = [];
			}
			this.isIndeterminate = false;
		},
		//批量下载源文件并打包
		downloadFile() {
			this.$parent.downloadFile(this.checkedDevice.toString(),'web2/report/odnr/down');
		},
		//删除设备源文件数据
		deleteFileData() {
			this.$confirm('您确定要永久删除报表吗?', '警告', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.axios.post(`web2/report/rmd/dsp`, {
					ids: this.checkedDevice.toString()
				}).then(res => {
					if (res.status) {
						this.$message({
							showClose: true,
							type: 'success',
							message: '删除成功!'
						});
						let {
							total,
							page,
							size
						} = this.item;
						this.item.page = this.calculatePages(total, page, size, this.checkedDevice.length);
						this.getDeviceFile();
					}
				})
			}).catch(() => {});
		},
		//预览
		preview(item, e=null) {
			if(e != null){
				this.theSelected(e);
			}
			this.$parent.preview(item,'web2/report/odnr/view', 'web2/report/odnr/down');
		},
		//删除列表中的数据，假删除
		removeList(item, index) {
			this.item.rows.splice(index, 1);
		},
		// 当前操作数据更改样式
		theSelected(e) {
			// console.log($(e.currentTarget));
			$('.reportExpPage .tableList').removeClass('selected-data');
			$(e.currentTarget)
				.parent()
				.parent()
				.addClass('selected-data');
		}
	},
	mounted() {
		// this.getTree();
	},
	beforeDestroy() {},
	watch: {}
}
