<template>
	<!-- 振动报告 -->
	<div class="reportPage">
		<reportExp :switchNavMenu="switchNavMenu" ref="reportExp" :authorityList="authorityList" v-show="page === 'reportExp'"></reportExp>
		<reportSta :switchNavMenu="switchNavMenu" ref="reportSta" :authorityList="authorityList" v-show="page === 'reportSta'"></reportSta>
		<summaryPage :switchNavMenu="switchNavMenu" ref="summary" :authorityList="authorityList" v-show="page === 'summary'"></summaryPage>
		<!-- 报告预览 -->
		<div class="preview-box Mask-box" v-if="previewStatus">
			<div class="pop-ups-content box-center">
				<div class="mian-box">
					<div class="left-box"><iframe frameborder="0" scrolling="auto" :src="src" width="1120px" height="100%" /></div>
					<div class="right-box">
						<el-button size="small" @click="downloadPDF">
							<div class="but-content">
								<span class="icon iconfont icon-daochu"></span>
								<span>导出PDF</span>
							</div>
						</el-button>
						<el-button size="small" @click="downloadWord">
							<div class="but-content">
								<span class="icon iconfont icon-daochu"></span>
								<span>导出word</span>
							</div>
						</el-button>
						<el-button size="small" @click="cancel">
							<div class="but-content">
								<span class="icon iconfont el-icon-close close"></span>
								<span>关闭</span>
							</div>
						</el-button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import reportExp from './reportExp/reportExp.vue';
import reportSta from './reportSta/reportSta.vue';
import summaryPage from './summary/summary.vue';

export default {
	name: 'report',
	components: { reportExp, reportSta, summaryPage },
	data() {
		return {
			page: 'reportExp',
			tree: [],
			reportExpInit: false,
			id: null, //传递过来的选中的文件id
			fileInfo: {},
			fileName: '',
			previewStatus: false, // 是否预览报告
			src: '', //报告预览地址
			parameter: {}, //参数
			authorityList: [], //权限数组
			downloadUrl: '' // word下载链接
		};
	},
	created() {
		this.parameter = this.$route.query;
		if (!this.parameter.hasOwnProperty('id')) {
			this.toRouter('index');
		}
		this.getPermissions();
		this.page = 'reportExp';
	},
	mounted() {
		this.getAllData(this.parameter);
	},
	methods: {
		//
		// 获取页面权限
		getPermissions() {
			this.authorityList = [];
			this.axios.get('web2/report/odnr/gakh').then(res => {
				if (res.status) {
					this.authorityList = res.data;
				}
			});
		},
		switchNavMenu(type, item) {
			this.page = type;
			if (type === 'reportExp' && !this.reportExpInit) {
				this.reportExpInit = true;
			}
			this.getAllData(this.parameter);
		},
		// 请求两个页面的数据
		getAllData(data) {
			// console.log(this.page);
			if (this.page == 'reportExp') {
				this.$refs.reportExp.setTheParameters(data);
			} else if (this.page == 'summary') {
				this.$refs.summary.setTheParameters(data);
			} else {
				this.$refs.reportSta.setTheParameters(data);
			}
		},
		//批量下载源文件并打包
		downloadFile(value, url = 'web2/report/odnr/dnrp') {
			var data = {
				params: {
					ids: value,
					responseType: 'arraybuffer'
				}
			};

			this.axios.get(url, data).then(res => {
				// // console.log(res);
				var headersName = res.headers['content-disposition'];
				var fileName = '';
				// // console.log(headersName);

				if (res.headers['content-type'].split(';')[0] != 'application/json') {
					if (headersName != undefined) {
						fileName = decodeURIComponent(escape(headersName.split('=')[1]));
					}
					// data 是返回来的二进制数据
					const data = res;
					const url = window.URL.createObjectURL(new Blob([data]));
					const link = document.createElement('a');
					link.style.display = 'none';
					link.href = url;
					link.setAttribute('download', fileName);
					document.body.appendChild(link);
					link.click();
					document.body.removeChild(link);
				} else {
					// arraybuffer转json
					let resBlob = new Blob([res]);
					let reader = new FileReader();
					reader.readAsText(resBlob, 'utf-8');
					reader.onload = () => {
						var type = typeof JSON.parse(reader.result);
						if (type == 'string') {
							this.$message({
								showClose: true,
								message: reader.result,
								type: 'error'
							});
						} else if (type == 'object') {
							let res = JSON.parse(reader.result);
							this.$message({
								showClose: true,
								message: res.message,
								type: 'error'
							});
						}
					};
				}
			});
		},
		// 预览
		preview(value, url = 'web2/report/odnr/prpt', download = '') {
			this.id = value.id;
			var val = {
				id: value.id
			};
			if (download) {
				this.downloadUrl = download;
			}
			this.axios.post(url, val, { responseType: 'arraybuffer' }).then(res => {
				// // console.log(res);
				const data = res;
				var headersName = res.headers['content-disposition'];
				// // console.log(headersName);

				if (res.headers['content-type'].split(';')[0] != 'application/json') {
					if (headersName != undefined) {
						this.fileName = decodeURIComponent(escape(headersName.split('=')[1]));
					}
					// data 是返回来的二进制数据
					var blob = new Blob([data], {
						type: 'application/pdf;charset=utf-8'
					});
					var href = window.URL.createObjectURL(blob);
					this.src = href;
					this.previewStatus = true;
				} else {
					// arraybuffer转字符串
					let resBlob = new Blob([res]);
					let reader = new FileReader();
					reader.readAsText(resBlob, 'utf-8');
					reader.onload = () => {
						var type = typeof JSON.parse(reader.result);
						if (type == 'string') {
							this.$message({
								showClose: true,
								message: reader.result,
								type: 'error'
							});
						} else if (type == 'object') {
							let res = JSON.parse(reader.result);
							this.$message({
								showClose: true,
								message: res.message,
								type: 'error'
							});
						}
					};
				}
			});
		},
		// 下载PDF
		downloadPDF() {
			const link = document.createElement('a');
			link.style.display = 'none';
			link.href = this.src;
			link.setAttribute('download', this.fileName);
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		},
		// 下载Word
		downloadWord() {
			if (this.downloadUrl) {
				this.downloadFile(this.id, this.downloadUrl);
			} else {
				this.downloadFile(this.id);
			}
		},
		// 关闭预览
		cancel() {
			this.previewStatus = false;
		}
	}
};
</script>

<style scoped lang="scss">
.reportPage {
	width: 100%;
	height: 100%;
	overflow: hidden;
	display: flex;

	// 报告预览
	.preview-box {
		position: fixed;
		// display: none;
		z-index: 10000;

		.pop-ups-content {
			width: 1190px;
			height: 100%;
			margin-top: 10px;

			.mian-box {
				width: 100%;
				height: 98%;
				display: flex;

				.left-box {
					height: 100%;
				}

				.right-box {
					display: flex;
					flex-direction: column;
					justify-content: flex-start;
					align-items: center;

					::v-deep {
						.el-button {
							padding: 0;
							width: 56px;
							height: 56px;
							margin-left: 14px;
							margin-bottom: 15px;
						}
					}

					.but-content {
						height: 100%;
						display: flex;
						flex-direction: column;
						justify-content: space-around;
						align-items: center;

						.icon {
							color: #409eff;
							font-size: 22px;
						}
						
						.close{
							font-size: 22px;
							font-weight: 600;
							color: #d80000;
						}

						.iconhoutaitubiao-25 {
							font-size: 35px !important;
						}

						span:last-child {
							color: #000;
						}
					}
				}
			}
		}
	}
}
</style>
